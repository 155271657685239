import React from 'react'

import Header from '../Header/Header'
import Footer from '../Footer'

// props interface
interface DefaultLayoutProps {
  title: string
  children?: React.ReactNode
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, title }) => {
  return (
    <div className="leading-normal tracking-normal">
      <div className="w-screen">
        <Header title={title} />
      </div>
      <div className="w-screen">{children}</div>
      <Footer />
    </div>
  )
}

export default DefaultLayout
