import React from 'react'
import { Link } from 'gatsby'

const Footer: React.FC = () => {
  return (
    <footer className="bg-white mx-5 sm:m-0">
      <div className="container mx-auto pt-8 md:pt-32">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1 flex-col mb-6">
            <Link
              className="flex text-linkColor no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
              to="/"
            >
              GONJITTI
            </Link>

            <small className="flex">
              Copyright © 2021 Gonjitti. All rights reserved.
            </small>
          </div>

          <div className="flex-1">
            <p className="text-gray-500 md:mb-6">コンテンツ</p>

            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/tags/%E7%B5%8C%E6%B8%88/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  経済
                </Link>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/tags/%E6%8A%95%E8%B3%87/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  投資
                </Link>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/tags/%E6%9B%B8%E8%A9%95/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  書評
                </Link>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/tags/notion/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  Notion
                </Link>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <Link
                  to="/tags/%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%82%B7%E3%83%B3%E3%82%AD%E3%83%B3%E3%82%B0/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  システムシンキング
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Social</p>

            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://twitter.com/gonjitti"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  Twitter
                </a>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://github.com/gonjitti"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  GitHub
                </a>
              </li>
            </ul>
          </div>

          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">個人開発サイト</p>

            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://nagashimi.com/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  流し見動画サイト、ながしみ
                </a>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://apps.apple.com/us/app/id1519362585"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  ワークアウトアプリBADDY(iOS)
                </a>
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                <a
                  href="https://baddyworkout.com/"
                  className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                >
                  ワークアウトアプリBADDY(web)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
