import React from 'react'

import DefaultLayout from '../components/Layout/default'

const NotFoundPage: React.FC = () => (
  <DefaultLayout title="見つからない">
    <div>
      <h1>404 NOT FOUND</h1>

      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </DefaultLayout>
)

export default NotFoundPage
