import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { IconContext } from 'react-icons'
import { FiRss } from 'react-icons/fi'
import { GatsbyImage } from 'gatsby-plugin-image'

// props interface
interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const headerComponentQueryData = useStaticQuery(graphql`
    query HeaderComponentQuery {
      iconFile: file(relativePath: { eq: "gonjitti_v2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <header className="container mx-auto">
      <nav className="flex items-center justify-between">
        <Link to="/" className="flex items-center m-3 tracking-wider font-bold">
          <GatsbyImage
            image={
              headerComponentQueryData.iconFile.childImageSharp.gatsbyImageData
            }
            alt={`Cover Image for ${title}`}
            className="object-contain bg-white h-auto w-14 sm:w-20"
          />

          <p>|{title}</p>
        </Link>

        <div className="flex items-center">
          <Link to="/feed" className="flex m-3 tracking-wider font-bold">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out"
              id="main-menu"
              aria-label="Main menu"
              aria-haspopup="true"
            >
              <p className="mr-1 whitespace-nowrap">RSS購読</p>

              <IconContext.Provider
                value={{ className: 'inline-block text-lg mb-1' }}
              >
                <div>
                  <FiRss />
                </div>
              </IconContext.Provider>
            </button>
          </Link>
        </div>
      </nav>
    </header>
  )
}

export default Header
